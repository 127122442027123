<template>
  <div class="sai-tag-list">
    <div class="sai-tag-list__title">{{ tagTitle }}</div>
    <div class="sai-tag-list__tags">
      <a
        class="sai-tag-list__tags__tag"
        :class="fillTagColor && 'fillColor'"
        v-for="(tagObj, index) in tags"
        v-bind:key="index"
        v-if="index < numberOfKeywordsDisplayed && tagObj.tag"
        @click.prevent="selectTag(tagObj)"
      >
        <span class="sai-tag-list__tags__tag__content">
          {{ convertTagText(tagObj) }}
        </span>
      </a>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { eventBus } from '@/eventBus';

@Component({
  props: {
    tags: Array,
    tagTitle: String,
    numberOfKeywordsDisplayed: Number,
  },
})
export default class PopularTagList extends Vue {
  get fillTagColor() {
    return !!this.$store.state.constObj.FILL_TAG_COLOR;
  }
  convertTagText(tagObj) {
    if (tagObj.displayText) {
      const dt = tagObj.displayText.toLowerCase();
      if (tagObj.tag.text.toLowerCase().indexOf(dt) === -1) {
        return tagObj.displayText;
      }
    }
    return tagObj.tag.text;
  }
  async selectTag(tagObj) {
    if (this.$store.state.user.isIphone && this.$store.state.onIME) {
      eventBus.$emit('toggleIme', false);
      return;
    }
    await this.$store.dispatch('tagSearch/resetInput');
    const displayText = this.convertTagText(tagObj);
    const selectedTag = Object.assign(tagObj.tag, { displayText });
    await this.$store.dispatch('tagSearch/addSelectedTag', selectedTag);
    this.$store.commit('tagSearch/setShow', true);

    if (!this.$store.state.user.isIphone) {
      eventBus.$emit('selectNextTag');
    }

    if (this.$store.state.ticket.resetFlag) {
      eventBus.$emit('resetTicket');
      return;
    }
    const newTicket = await this.updateTicket(selectedTag);
    await this.$ticket.setData(newTicket);
    if (this.$store.state.user.isIphone && this.$store.state.onIME) {
      setTimeout(() => {
        this.$ticket.post();
      }, 200);
      return;
    }
    this.$ticket.post();
  }
  async updateTicket(tag) {
    const startTime =
      this.$store.state.ticket.startTime || String(new Date().getTime());
    this.$store.commit('ticket/setStartTime', startTime);

    const historyActions = [
      { type: 'tag', value: 'popular' },
      { type: '', value: 'search' },
    ];
    await this.$store.dispatch(
      'ticket/addHistoryActionFaqChannels',
      historyActions
    );

    const keywordTags = this.$store.getters['tagSearch/selectedKeywordTags'];
    const tagActiveSet =
      keywordTags.length === 0
        ? []
        : keywordTags.map(t => {
            const v = { id: t.id, text: t.text };
            if (t.displayText) {
              v.label = t.displayText;
            }
            return v;
          });

    if (
      keywordTags.length > 0 &&
      this.$store.state.ticket.tagUsedSet.length === 0
    ) {
      this.$store.commit('ticket/setTagUsedSet', tagActiveSet);
    } else {
      let tagUsedSet = { id: tag.id, text: tag.text };
      if (tag.displayText) {
        tagUsedSet.label = tag.displayText;
      }
      this.$store.commit('ticket/addTagUsedSet', tagUsedSet);
    }

    const query = this.$store.state.tagSearch.searchText;
    if (query && this.$store.state.ticket.historyQuery.length === 0) {
      this.$store.commit('ticket/setHistoryQuery', [query]);
    }
    return this.generateTicket(startTime, query, tagActiveSet);
  }
  generateTicket(startTime, query, tagActiveSet) {
    const newTicket = {
      origin: 'window',
      user_agent: this.$store.state.user.userAgent,
      user_id: this.$store.state.user.Id,
      product_type: this.$store.state.productType,
      start_time: startTime,
      end_time: String(new Date().getTime()),
      query: query,
      tag_active_set: tagActiveSet,
      tag_used_set: this.$store.state.ticket.tagUsedSet,
      history_query: this.$store.state.ticket.historyQuery,
      history_action_faq_channel: this.$store.state.ticket
        .historyActionFaqChannel,
      status: 'open',
    };

    return newTicket;
  }
}
</script>

<style lang="scss" scoped>
@import '../style/component/tagList';

.sai-content__wrap__initial {
  .sai-tag-list {
    padding: 0 0 12px 0;

    &__title {
      padding-bottom: 12px;
      font-size: 20px;
      font-weight: 700;
    }
  }
}
</style>
