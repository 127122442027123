<template>
  <div class="sai-initial-form" :class="{ mobile: isSP }">
    <div class="sai-initial-form__title">
      <div class="text">{{ title }}</div>
    </div>
    <div class="sai-initial-form__input">
      <input
        type="text"
        required="true"
        @input="updateInput($event.target.value)"
        @keydown.enter="onClick(value)"
      />
    </div>
    <div class="sai-initial-form__caption">
      <div class="text" v-html="caption"></div>
    </div>
    <div class="sai-initial-form__button">
      <button @click="onClick(value)" v-bind:disabled="!validInput(value)">
        {{ buttonText }}
      </button>
    </div>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import InitialFormMixin from '@/mixins/InitialFormMixin';

@Component({})
export default class InitialForm extends mixins(InitialFormMixin) {}
</script>

<style lang="scss" scoped>
@import '../style/component/initialForm';
</style>
