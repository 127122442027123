import resource from './resource';
import search from './search';
import { utilityModules } from './util';

// callbackでlistを分類し、
// 2以上ある分類に属するitemのリストを返す
const firstCategory = (list, callback) => {
  const map = [];
  for (const item of list) {
    const evaluation = callback(item);
    if (evaluation !== false) {
      if (!map[evaluation]) {
        map[evaluation] = [];
      }
      map[evaluation].push(item);
    }
  }
  return map.find(item => item && item.length > 1) || [];
};

class TalkScriptUtil {
  filterList({ selectedTagList, byStep, searchText, talkScripts, limit }) {

    let lastNodeTag = null;
    const selectedKeywordTags = [];

    for (const tag of selectedTagList) {
      if (tag.type === 'node') {
        lastNodeTag = tag;
      } else if (tag.type === 'keyword') {
        selectedKeywordTags.push(tag);
      }
    }
  
    const filteredList = talkScripts.filter(item => {

      return (
        item.talkScriptType === 'leaf' &&
        (!lastNodeTag ||
          item.ancesters.some(_ancester => _ancester.id === lastNodeTag.id)) &&
        selectedKeywordTags.every(
          (tag) => {
            //TO BE CORRECTLY FIXED
            const tagKeyword = utilityModules.cleanText(tag.index);
            return item.keywords.has(tagKeyword) || (tag.yomi && item.keywords.has(tag.yomi))
          }
        )
      );
    });
    
    let outputList = filteredList;
    if (filteredList && limit){
      outputList = filteredList.splice(0, limit)
    }
    
    return outputList;
  }

  // getCandidateTags({ selectedTagSet, list }) {
  //   if (!list) {
  //     return [];
  //   }
  //   const keywordMap = {};
  //   const anscesterSet = new Set();
  //   for (const item of list) {
  //     // if (item.type === 'leaf') {
  //     for (const keyword of item.keywords) {
  //       keywordMap[keyword] = keywordMap[keyword] || [];
  //       keywordMap[keyword].push(item);
  //     }
  //     for (const anscester of item.ancesters) {
  //       anscesterSet.add(anscester);
  //     }
  //     // }
  //   }

  //   const categoryTags = firstCategory(
  //     anscesterSet,
  //     item => item.ancesters.length
  //   );
  //   const keywordTags = Object.entries(keywordMap)
  //     .filter(
  //       ([keyword, items]) => items.length < list.length && items.length > 1
  //     )
  //     .sort((a, b) => b[1].length - a[1].length)
  //     .map(([keyword, items]) => ({
  //       id: 'keyword:' + keyword,
  //       type: 'keyword',
  //       title: keyword,
  //       kana: keyword,
  //       items,
  //     }));
  //   return categoryTags.concat(keywordTags);
  // }
}

const talkScriptUtil = new TalkScriptUtil(resource);
export default talkScriptUtil;
