<template>
  <span v-html="htmlString"></span>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import HighlightMixin from '@/mixins/HighlightMixin';

@Component({})
export default class Highlight extends mixins(HighlightMixin) {}
</script>

<style lang="scss" scoped>
@import '~product/style/variables/index';
/deep/ .hl {
  background-color: $highlightColor;
}
</style>
