const REG_FAQ = new RegExp(/[\[](FAQ.*?)[\]]/);

const generateFaqNo = text => {
  if (!text) {
    return '';
  }
  return text.match(REG_FAQ) ? text.match(REG_FAQ)[1] : '';
};

const existsFaqNo = text => {
  if (!text) {
    return false;
  }
  return text.match(REG_FAQ);
};

const cleanText = text => {
  if (!text) {
    return '';
  }
  return removeFaqNo(text);
};

const removeFaqNo = text => {
  if (!text) {
    return '';
  }
  return text.replace(REG_FAQ, '');
};

const textParser = {
  existsFaqNo,
  cleanText,
  generateFaqNo,
};

export default textParser;
