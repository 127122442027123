<template>
  <div
    class="resolveEnquete"
    v-show="currentValue && currentValue.viewType === 'result'"
  >
    <div>
      <div class="resolveButtonArea">
        <div class="buttonWrapper">
          <a
            :class="['resolveButton', showLastMessage && 'disabled']"
            @click.prevent="resolved"
          >
            {{ resolveMessage }}
          </a>
          <a
            :class="['resolveButton', showLastMessage && 'disabled']"
            @click.prevent="unresolved"
          >
            {{ unsolvedMessage }}
          </a>
        </div>
      </div>
    </div>
    <div id="sai-reset"></div>
    <div v-show="showLastMessage">
      <div class="lastMessage sai-caption" v-html="afterFeedbackMessage"></div>
      <div class="resolveButtonArea reset">
        <a @click.prevent="reset" class="resolveButton">{{ goToTopLabel }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import ResourceListFooterMixin from '@/mixins/ResourceListFooterMixin';

@Component({})
export default class ResourceListFooter extends mixins(
  ResourceListFooterMixin
) {}
</script>

<style lang="scss" scoped>
@import '../style/component/resourceListFooter';
</style>
