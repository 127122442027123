<template>
  <div class="ColumnNavigation">
    <div class="navigationBreadWrapper">
      <Breadcrumbs :range="range" />
    </div>
    <div class="navigationContentWrapper">
      <div
        ref="navigationContent"
        :class="{ navigationContent: true, isRoot: routes.length <= 1 }"
        :style="{ left: navigationLeft + 'px' }"
      >
        <div
          class="page"
          v-for="(route, routeIndex) in routes"
          :key="routeIndex"
          :style="{
            left: getColumnLeft(routeIndex) + 'px',
            width: getColumnWidth(routeIndex) + 'px',
          }"
        >
          <component
            :is="route.componentName"
            :index="routeIndex"
            :currentValue="route"
            :prevValue="
              $store.state.navigation.routes[baseIndex + routeIndex - 1]
            "
            :nextValue="
              $store.state.navigation.routes[baseIndex + routeIndex + 1]
            "
            :currentLocal="locals[routeIndex]"
            :prevLocal="locals[routeIndex - 1]"
            :nextLocal="locals[routeIndex + 1]"
            :localActive="routeIndex === localIndex"
            :active="baseIndex + routeIndex === $store.state.navigation.index"
            :enableScroll="true"
          ></component>
        </div>
      </div>
    </div>
    <a
      v-show="canMovePositionBy(-1)"
      class="navigationButton navigationButtonLeft"
      @click.prevent="movePositionBy(-1)"
    >
      <font-awesome-icon icon="chevron-left" />
    </a>

    <a
      v-show="canMovePositionBy(1)"
      class="navigationButton navigationButtonRight"
      @click.prevent="movePositionBy(1)"
    >
      <font-awesome-icon icon="chevron-right" />
    </a>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import NavigationMixin from '@/mixins/NavigationMixin';

@Component({})
export default class ColumnNavigation extends mixins(NavigationMixin) {}
</script>

<style lang="scss" scoped>
@import '../style/component/columnNavigation';
</style>
