<template>
  <div class="sai-breadcrumbs">
    <div class="sai-breadcrumbs__top">
      <div class="sai-breadcrumbs__top__item" @click="move(0)">
        <div class="sai-breadcrumbs__top__item__text">
          {{ topText }}
        </div>
      </div>
    </div>
    <div id="sai-bread-content" class="sai-breadcrumbs__content scrollX">
      <div class="sai-breadcrumbs__content__items">
        <div
          class="sai-breadcrumbs__content__items__item"
          :class="['active', localIndex === index && 'selected']"
          v-for="(item, index) in list"
          v-if="index > 0"
          @click="move(index)"
          :style="{ 'z-index': 1000 - index }"
          :key="index"
        >
          <a class="sai-breadcrumbs__content__items__item__text active">
            <span>
              {{ generateTitle(item.text) }}
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadcrumbsMixin from '@/mixins/BreadcrumbsMixin';
import Component, { mixins } from 'vue-class-component';

@Component({})
export default class Breadcrumbs extends mixins(BreadcrumbsMixin) {}
</script>

<style lang="scss" scoped>
@import '../style/component/breadcrumbs';
</style>
