<template>
  <div class="sai-image-tag" @click.prevent="$emit('selectTag')">
    <div class="icon">
      <img :alt="displayText" :src="iconSrc(icon)" />
    </div>
    <div class="border"></div>
    <div class="texts">
      <div class="text" v-html="displayText"></div>
      <div class="subText" v-html="displaySubText"></div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  props: {
    displayText: String,
    displaySubText: String,
    icon: String,
  },
})
export default class ImageTag extends Vue {
  iconSrc(file) {
    return require(`product/resource/img_tags/${file}`);
  }
}
</script>

<style lang="scss" scoped>
@import '../style/component/imageTag';
</style>
