<template>
  <div class="sai-tag-category">
    <div class="search_title">
      <span class="search_title__text">
        {{ title }}
      </span>
    </div>
    <div class="search_btns cf">
      <div class="search_btn" v-for="(t, idx) in tags" :key="idx">
        <ImageTag
          :displayText="t.displayText"
          :displaySubText="t.displaySubText"
          :icon="t.icon"
          @selectTag="selectRegion(t.text)"
        ></ImageTag>
      </div>
    </div>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import TagCategoryMixin from '@/mixins/TagCategoryMixin';

@Component({})
export default class TagCategory extends mixins(TagCategoryMixin) {
  async updateTicket(tags) {
    const startTime =
      this.$store.state.ticket.startTime || String(new Date().getTime());
    this.$store.commit('ticket/setStartTime', startTime);
    const historyActions = [
      { type: 'tag', value: 'customizedPopular' },
      { type: '', value: 'search' },
    ];
    await this.$store.dispatch(
      'ticket/addHistoryActionFaqChannels',
      historyActions
    );
    const keywordTags = this.$store.getters['tagSearch/selectedKeywordTags'];
    const tagActiveSet =
      keywordTags.length === 0
        ? []
        : keywordTags.map(t => {
            const v = { id: t.id, text: t.text };
            if (t.displayText) {
              v.label = t.displayText;
            }
            return v;
          });
    if (
      keywordTags.length > 0 &&
      this.$store.state.ticket.tagUsedSet.length === 0
    ) {
      this.$store.commit('ticket/setTagUsedSet', tagActiveSet);
    } else {
      const tagUsedSet = tags.map(t => {
        const v = { id: t.id, text: t.text };
        if (t.displayText) {
          v.label = t.displayText;
        }
        return v;
      });
      await this.$store.dispatch('ticket/addTagUsedSets', tagUsedSet);
    }
    const query = this.$store.state.tagSearch.searchText;
    if (query && this.$store.state.ticket.historyQuery.length === 0) {
      this.$store.commit('ticket/setHistoryQuery', [query]);
    }
    return this.generateTicket(startTime, query, tagActiveSet);
  }
}
</script>

<style lang="scss" scoped>
@import '../style/component/tagCategory';
</style>
