import { generateStore } from '@/store/index';
import enquete from '@/store/modules/enquete';
import customizedUser from '@/store/modules/userWithoutId';
import createPersistedState from 'vuex-persistedstate';
import productTypeConst from 'ptype/page/const/index';
import productConst from 'product/const/index';

const Const = { ...productTypeConst, ...productConst };
const localStoreSettings = {
  modules: {
    enquete,
  },
  plugins: [],
};

if (Const.WITH_INITIAL_FORM) {
  localStoreSettings.modules.user = customizedUser;
  localStoreSettings.plugins.push(
    createPersistedState({
      key: `sAIFaqClient-${process.env.PRODUCT_ID}`,
      paths: ['user.Id'],
    })
  );
}
const store = generateStore(localStoreSettings);

export default store;
