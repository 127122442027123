export class AsyncOutOfOrderError extends Error {
  constructor(message = '') {
    super('Async Out of Order Error ' + message);
  }
}

export class AsyncUpdate {
  requestId = 0;
  updateId = 0;
  async request(executor) {
    this.requestId += 1;
    const requestId = this.requestId;

    const value = await executor();
    if (this.updateId < requestId) {
      this.updateId = requestId;
      return value;
    } else {
      throw new AsyncOutOfOrderError();
    }
  }
}

const randomNums = ()=>{
  var digits = 6;
  var c = "0123456789";
  var cl = c.length;
  var r = "";
  for (var i = 0; i < digits; i++) {
    r += c[Math.floor(Math.random() * cl)];
  }
  return r;
}

const cleanText = (text)=>{
  return (
    text &&
    text
      .replace(/[Ａ-Ｚａ-ｚ０-９]/g, s => {
        return String.fromCharCode(s.charCodeAt(0) - 65248);
      })
      .toLowerCase()
  );
}

export const utilityModules = {randomNums, cleanText}

