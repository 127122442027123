<template>
  <div id="sai-tag-app">
    <div id="sai-tag-main">
      <router-view />
    </div>
  </div>
</template>

<script>
import productTypeConst from 'ptype/page/const/index';
import productConst from 'product/const/index';
const Const = { ...productTypeConst, ...productConst };

export default {
  name: 'App',
  async created() {
    await this.$store.dispatch('Initialize', Const);
  },
};
</script>
