var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ScrollGuide',{ref:"scrollGuide"},[_c('div',{directives:[{name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"}],class:[
      'ResourceList',
      _vm.active && 'active',
      _vm.active && 'pageScrollTarget',
      _vm.enableScroll && 'scrollY',
      _vm.currentValue && _vm.currentValue.viewType === 'result' && 'result',
      _vm.currentValue && _vm.currentValue.viewType === 'scenario' && 'scenario' ]},[_c('div',{ref:"scrollWrapper",staticClass:"scrollWrapper"},[(_vm.currentValue.viewType !== 'talkScript')?_c('div',{staticClass:"head"},[_c('div',{staticClass:"title faq"},[_c('div',{staticClass:"icon"},[_c('i',{staticClass:"top"})]),_vm._v(" "),_c('div',{staticClass:"text"},[_vm._v("\n            "+_vm._s(_vm.cleanText(_vm.lastScript.text))+"\n          ")])]),_vm._v(" "),(_vm.existsFaqNo(_vm.lastScript.text))?_c('div',{staticClass:"subInfo"},[_c('span',[_vm._v(_vm._s(_vm.generateFaqNo(_vm.lastScript.text)))])]):_vm._e(),_vm._v(" "),_vm._l((_vm.scenarios),function(scenario,index){return (!_vm.currentValue.talkScriptId)?_c('div',{key:index,staticClass:"title",class:index === _vm.scenarios.length - 1 && 'lastChoice'},[_c('div',{staticClass:"icon"},[_c('i',{staticClass:"choice"})]),_vm._v(" "),_c('div',{staticClass:"text"},[_c('span',[_vm._v(_vm._s(scenario.text))])])]):_vm._e()})],2):_vm._e(),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.item && _vm.item.caption),expression:"item && item.caption"}],staticClass:"sai-caption",domProps:{"innerHTML":_vm._s(_vm.item && _vm.item.caption)}}),_vm._v(" "),_c('div',{ref:"items",staticClass:"items"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,class:[
            'item',
            _vm.isSelected(item) && 'selected',
            'talkScriptType-' + item.talkScriptType,
            _vm.firstIndex && 'first' ]},[_c('div',{staticClass:"relation"},[_c('div',{staticClass:"relation-line"})]),_vm._v(" "),_c('div',{class:['itemContent', index === 0 && 'firstItem'],attrs:{"item-debug":JSON.stringify(item)},on:{"click":function($event){$event.preventDefault();return _vm.openHandler(item)}}},[_c('span',[_vm._v(_vm._s(item.text))]),_vm._v(" "),_c('font-awesome-icon',{attrs:{"icon":"chevron-right"}})],1)])}),0),_vm._v(" "),_c('ResourceListFooter',{attrs:{"currentValue":_vm.currentValue,"lastScript":_vm.lastScript,"withEnquete":_vm.feedbackWithEnquete,"withSuggestion":_vm.feedbackWithSuggestion},on:{"scrollBottom":_vm.scrollBottom}})],1)]),_vm._v(" "),_c('div',{ref:"anchor",staticClass:"anchor"},[_c('span',{staticClass:"anchorBar"}),_vm._v(" "),_c('span',{staticClass:"anchorBox"})])])}
var staticRenderFns = []

export { render, staticRenderFns }