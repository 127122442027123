import Vue from 'vue';
import Component from 'vue-class-component';

@Component({})
export default class InitialFormMixin extends Vue {
  title = this.$store.state.constObj.INITIAL_FORM_TITLE;
  caption = this.$store.state.constObj.INITIAL_FORM_CAPTION;
  buttonText = this.$store.state.constObj.INITIAL_FORM_BUTTON_TEXT;
  value = '';
  onIME = false;

  get isSP() {
    return this.$store.state.user.isSP;
  }
  validInput(t) {
    return t && t.trim().length > 0;
  }
  updateInput(t) {
    this.value = t;
  }
  onClick(value) {
    if (!this.validInput(value)) {
      return;
    }
    this.$emit('postInitialForm', value);
  }
}
