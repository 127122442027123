import Vue from 'vue';
import AsyncComputed from 'vue-async-computed-non-debounce';
import VueScrollTo from 'vue-scrollto';
import Router from 'vue-router';
import { domReady } from '@';
import '@/libs/emulateScroll';
import { SAITicketService } from '@sciseed/sai-ticket/dist/sai-ticket.common.js';

import App from 'ptype/page/App';
import store from 'ptype/page/store/index';
import routerConfig from 'ptype/page/router';
import 'ptype/page/style/index.scss';

import Autocomplete from '@/components/Autocomplete';
import CategorySearch from '@/components/CategorySearch';
import FaqClientMultiple from '@/components/FaqClientMultiple';
import FaqClientWrapper from '@/components/FaqClientWrapper';
import Frequent from '@/components/Frequent';
import History from '@/components/History';
import Loading from '@/components/Loading';
import ScrollGuide from '@/components/ScrollGuide';
import ScrollGuideContent from '@/components/ScrollGuideContent';
import VerticalNavigation from '@/components/VerticalNavigation';
import productTypesComponents from 'ptype/page/components';
import productComponents from 'product/components';

import productTypeConst from 'ptype/page/const/index';
import productConst from 'product/const/index';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faList } from '@fortawesome/free-solid-svg-icons/faList';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { faRedoAlt } from '@fortawesome/free-solid-svg-icons/faRedoAlt';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faTag } from '@fortawesome/free-solid-svg-icons/faTag';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons/faMinusCircle';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

const Const = { ...productTypeConst, ...productConst };
const defaultComponents = {
  Autocomplete,
  CategorySearch,
  FaqClientMultiple,
  FaqClientWrapper,
  Frequent,
  History,
  Loading,
  ScrollGuide,
  ScrollGuideContent,
  VerticalNavigation,
};

export const init = () => {
  // Call needed modules
  Vue.use(AsyncComputed);
  Vue.config.productionTip = false;
  const ticketConfig = {
    ticketInterval: 1800,
  };
  Vue.use(SAITicketService, {
    config: ticketConfig,
    url: process.env.TICKET_URL,
    productId: process.env.RESOURCE_ID,
  });

  // Add font to library and add font-awesome to global components
  library.add(
    faList,
    faChevronRight,
    faChevronLeft,
    faSearch,
    faSignOutAlt,
    faRedoAlt,
    faTimes,
    faTag,
    faPlusCircle,
    faMinusCircle
  );
  Vue.component('font-awesome-icon', FontAwesomeIcon);

  // Call global css
  if (!Const.USE_CUSTOMIZED_HTML) {
    require('@/style/global/index.scss');
  }

  // Register Components
  const components = Object.assign(
    {},
    defaultComponents,
    productTypesComponents,
    productComponents
  );
  for (const componentName of Object.keys(components)) {
    Vue.component(componentName, components[componentName]);
  }

  // Call vue-scroll
  const scrollConfig = {
    duration: 500,
    easing: 'ease',
    offset: 0,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: true,
    y: true,
  };
  Vue.use(VueScrollTo, scrollConfig);

  // Call vue-router
  Vue.use(Router);

  // Setting of Vue instance
  const vueSetting = {
    el: '#sai-tag-app',
    store: store,
    components: { App },
    template: '<App/>',
    router: new Router(routerConfig),
  };

  /* eslint-disable no-new */
  new Vue(vueSetting);
};

// Init
domReady(() => {
  init({});
});
