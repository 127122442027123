import resource from './resource';
import { TagMatchingManager } from '../libs/tagRetrieval';
import dataResource from './dataResource';
import talkScript from './talkScript';
import talkScriptUtil from './talkScriptUtil';
import scriptSearch from './search';
import { utilityModules } from './util';

class TagSearch {
  maxNumOfTags = 100;
  numOfInitialScript = 30;
  maxNumOfScript = 100;
  readyPromise = null;
  constructor(_resource) {
    this.resource = _resource;
  }

  ready() {
    if (!this.readyPromise) {
      this.readyPromise = this.prepare();
    }
    return this.readyPromise;
  }
  async prepare() {
    // TO BE REPLACED: data resources
    const data = await this.resource.ready();
    if (!this.tagMatchingManager){
      this.tagMatchingManager = new TagMatchingManager(data);
    }
    if (typeof window === 'object') {
      window.tagMatchingManager = this.tagMatchingManager;
    }
  }

  async buildFullTextSearchQuery(wordList) {
    await this.ready();
    
    const allPatterns = wordList.map(w=>{
      let synonyms = this.tagMatchingManager.getSynonyms(w)
      synonyms = synonyms ? synonyms.includes(w) ? synonyms : [...synonyms, w] : [w]
      return synonyms.filter(w=>w.length>0)
    })
    return allPatterns.filter(item=>item.length>0)
    // let currentState = []
    // for (const synonyms of allPatterns){
    //   const newState = []
    //   for (const word of synonyms){

    //     if (currentState.length>0){
    //       for (const elm of currentState){
    //         newState.push([...elm, word])
    //       }
    //     }else{
    //       newState.push([word])
    //     }
    //   }
    //   currentState = [].concat(newState)
    //   console.log(currentState)
    // }
    // return currentState
  }

  async getTopTags() {
    await this.ready();
    await dataResource.ready({ resourceName: 'tag' });
    return dataResource.getListSync({
      resourceName: 'tag',
      mode: 'top',
    });
  }

  async getTopTagsByYomi(){
    const tags = await this.getTopTags();
    const tagByYomi = {}
    for (let i=0; i<tags.length; i++){
      const tagItem = tags[i].tag;
      if (tagItem.yomi && tagItem.weight>0) {
        const index = tagItem.yomi[0];
        if (!(index in tagByYomi)) {
          tagByYomi[index] = [tagItem];
        } else {
          tagByYomi[index].push(tagItem);
        }
      }
    }
    for (const key in tagByYomi) {
      tagByYomi[key].sort((a, b) => {
        a = this._katakanaToHiragana(a.yomi.toString());
        b = this._katakanaToHiragana(b.yomi.toString());
        if (a < b) {
          return -1;
        } else if (a > b) {
          return 1;
        }
        return 0;
      });
    }
    return tagByYomi;  
  }

  _katakanaToHiragana(src) {
    return src.replace(/[\u30a1-\u30f6]/g, function(match) {
      var chr = match.charCodeAt(0) - 0x60;
      return String.fromCharCode(chr);
    });
  }


  async tagify(query = '', mode = 'exactMatch') {
    await this.ready();
    await dataResource.ready({ resourceName: 'tag' });

    let candidateTags, queryItems;
    if (mode === 'exactMatch') {
      candidateTags = this.tagMatchingManager.extractTags(query);
      if (!(candidateTags.length > 0 && candidateTags.every(t => t))) {
        return [];
      }
    } else {
      queryItems = this.tagMatchingManager.formQueryItems(null, query);
      candidateTags = this.tagMatchingManager.getExactlyMatchedTags(
        queryItems,
        true
      );
      if (!(candidateTags.length > 0 && candidateTags.every(t => t))) {
        return [];
      }
    }
    const outputTags = candidateTags.map((tid, index) => {
      const tag = dataResource.getItemSync({
        resourceName: 'tag',
        tagId: tid,
      });
      if (queryItems) {
        tag.query = queryItems[index];
      }
      return tag;
    });
    return outputTags;
  }

  async getCandidateTags(tags, text, searchCondition) {

    await this.ready();

    let searchFilter = {};
    if (searchCondition.category.length > 0) {
      const talkScripts = await dataResource.getList({
        resourceName: 'talkScript',
      });
      const filteredScripts = talkScriptUtil.filterList({
        selectedTagList: searchCondition.category,
        talkScripts,
      });
      searchFilter.scripts = filteredScripts.map(s => s.talkScriptId);
    }

    await dataResource.ready({ resourceName: 'tag' });
    const isTextQuery = !!text;
    const tagsText = tags.map(t => t.index);
    const queryText = utilityModules.cleanText(text)
    const searchResult = this.tagMatchingManager
      .getSearchResult(tagsText, queryText, searchFilter)
      .map(candidateTag => {
        return {
          "displayText": candidateTag.w,
          "isTextQuery": isTextQuery,
          "numOfScripts": candidateTag.s.length,
          "tag": dataResource.getItemSync({
            resourceName: 'tag',
            tagId: candidateTag.t,
          }),
        };
      })
      .filter(item => item && item.tag.weight>0)
      .slice(0, this.maxNumOfTags);

    return searchResult;
  }

  async getInitialTags() {
    await this.ready();
    await dataResource.ready({ resourceName: 'tag' }); 
    return await dataResource.getList({ resourceName: 'tag', mode: 'init', limit: this.maxNumOfTags });
  }

  async getCandidateScript(selectedTags, query) {
    const startTime = new Date();
    await this.ready();
    await dataResource.ready({ resourceName: 'talkScript' });

    await talkScript.ready()

    // let searchText = selectedTags.map(e => e.text).join(' ');
    // searchText = query ?  `${searchText} ${query}` : searchText
    const talkScripts = query
      ? (await scriptSearch.search(query))
          .map(id =>
            dataResource.getItemSync({
              resourceName: 'talkScript',
              talkScriptId: id,
            })
          )
          .filter(item => item)
      : (await dataResource.getList({
          resourceName: 'talkScript',
        }))
    const filteredTalkScript = talkScriptUtil.filterList({
      selectedTagList: selectedTags,
      searchText: query,
      talkScripts,
      limit: this.maxNumOfScript,
    });
    return filteredTalkScript
  }
}

const search = new TagSearch(resource);
export default search;
