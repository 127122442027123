import axios from 'axios';
import { utilityModules } from '@/common/util';
import dataResource from '@/common/dataResource';

class Frequent {
  readyPromise = null;
  initFaqFileUrl = null;
  faqList = [];

  async getFaqList(initFaqFileUrl){
    this.initFaqFileUrl = initFaqFileUrl;
    await this.ready();
    return this.faqList;
  }

  ready(){
    if (!this.readyPromise && this.initFaqFileUrl) {
      this.readyPromise = this.prepare();
    }
    return this.readyPromise;
  }

  async prepare() {
    const url = `${this.initFaqFileUrl}?v=${utilityModules.randomNums()}`;
    const yokuaruList = await axios.get(url);
    if (yokuaruList.data && yokuaruList.data.length > 0) {
      this.faqList = [];
      const ids = yokuaruList.data.map(s => s.id);
      await dataResource.ready({ resourceName: 'talkScript' });
      for(let i = 0; i < ids.length; i++) {
        const item = dataResource.getItemSync({
          resourceName: 'talkScript',
          talkScriptId: ids[i],
        });
        if (item) {
          this.faqList.push(item);
        }
      }
    }
  }
}

const frequent = new Frequent();

export default frequent;
