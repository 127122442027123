<template>
  <div
    class="sai-enquete"
    :class="{
      single: singleEnquete,
      ie: $store.state.user.isIE,
    }"
  >
    <div class="inner">
      <div class="wrapper" v-if="!done" :style="{ maxHeight: height() }">
        <div class="head">
          <div class="closeButton">
            <a @click="close"><font-awesome-icon icon="times"/></a>
          </div>
          <div class="title sai-caption" v-html="heading"></div>
          <div class="alert" v-if="invalidValues">{{ alertMessage }}</div>
        </div>
        <div class="body">
          <div class="content">
            <div class="questions">
              <div class="question" v-for="item in enquete" :key="item.index">
                <div class="title">
                  <div class="icon">{{ 'Q' + item.index }}</div>
                  <div class="text">
                    <span class="tag" v-if="item.required">
                      {{ enqueteRequiredMarkText }}
                    </span>
                    <span>{{ item.title }}</span>
                  </div>
                </div>
                <div class="description">{{ item.description }}</div>
                <div class="textarea" v-if="item.type === 'textarea'">
                  <textarea
                    type="text"
                    :rows="item.rows || 8"
                    @input="updateInputText(item.index, $event.target.value)"
                  />
                </div>
                <div v-else :class="item.type">
                  <div
                    class="choice"
                    v-for="(c, c_idx) in item.choices"
                    :key="c_idx"
                  >
                    <input
                      :type="item.type"
                      :id="getChoiceId(item.index, c_idx)"
                      :name="getChoiceName(item.index)"
                      :value="c.value"
                      @change="
                        updateSelectedChoices(
                          $event.target.checked,
                          item.index,
                          item.type,
                          c.value,
                          c.label
                        )
                      "
                    />
                    <label :for="getChoiceId(item.index, c_idx)">
                      {{ c.label }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="buttons">
              <a class="button send" @click="send">{{ sendText }}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="wrapper completed" v-if="done">
        <div class="body">
          <div class="content">
            <div class="message sai-caption" v-html="message"></div>
            <div class="buttons">
              <a class="button close" @click="close">{{ closeText }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import EnqueteMixin from '@/mixins/EnqueteMixin';

@Component({})
export default class Enquete extends mixins(EnqueteMixin) {
  async mounted() {
    this.fetchConst(this.type);
    await this.fetchEnquete(this.type);
    this.fetcEnqueteValues(this.type);
    this.setSingleEnquete(this.enquete);
  }
}
</script>

<style lang="scss" scoped>
@import '../style/component/enquete';
</style>
